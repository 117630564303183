const schedule = {
  Monday: [
    { name: "Basics - NoGi", start: 7.5, end: 8.5 },
    { name: "Basics - NoGi", start: 12, end: 13 },
    { name: "Kids NoGi", start: 17.5, end: 18.25 },
    { name: "Basics - NoGi", start: 18.25, end: 19.25 },
    { name: "Advanced - NoGi", start: 19.25, end: 20.25 }
  ],
  Tuesday: [
    { name: "Basics - Gi", start: 7.5, end: 8.5 },
    { name: "Basics - Gi", start: 12, end: 13 },
    { name: "Kids - Gi", start: 17.5, end: 18.25},
    { name: "Basics - Gi", start: 18.25, end: 19.25 },
    { name: "Advanced - Gi", start: 19.25, end: 20.25 }
  ],
  Wednesday: [
    { name: "Basics - NoGi", start: 7.5, end: 8.5 },
    { name: "Basics - NoGi", start: 12, end: 13 },
    { name: "Kids - NoGi", start: 17.5, end: 18.25 },
    { name: "Wrestling", start: 18.25, end: 19.25 },
    { name: "Advanced - NoGi", start: 19.25, end: 20.25 }
  ],
  Thursday: [
    { name: "Basics - Gi", start: 7.5, end: 8.5 },
    { name: "Basics - Gi", start: 12, end: 13 },
    { name: "Kids - Gi", start: 17.5, end: 18.25},
    { name: "Basics - Gi", start: 18.25, end: 19.25 },
    { name: "Advanced - Gi", start: 19.25, end: 20.25 }
  ],
  Friday: [
    { name: "Basics - NoGi", start: 7.5, end: 8.5 },
    { name: "Basics - NoGi", start: 12, end: 13 },
    { name: "Kids - NoGi", start: 17.5, end: 18.25 },
    { name: "Wrestling", start: 18.25, end: 19.25 },
    { name: "Advanced - NoGi", start: 19.25, end: 20.25 }
  ],
  Saturday: [ 
    { name: "Kids BJJ (Gi)", start: 11.25, end: 12 },
    { name: "Basics - Gi", start: 12, end: 13},
   
  ]
  ,
  Sunday: [
    { name: "Open Mat", start: 12, end: 13},
  ]
};
export default schedule  