const cancellations = [
    {day: 'December 11', name: "Kids - Nogi", time: "5:30 pm - 6:15 pm", reason: "Head Coach is away, competing at the Nogi World Championship"},
    {day: 'December 12', name: "Basics - Gi", time: "7:30 am - 8:30 am", reason: "Head Coach is away, competing at the Nogi World Championship"},
    {day: 'December 12', name: "Basics - Gi", time: "12:00 pm - 1:00 pm", reason: "Head Coach is away, competing at the Nogi World Championship"},
    {day: 'December 12', name: "Kids - Gi", time: "5:30 pm - 6:15 pm", reason: "Head Coach is away, competing at the Nogi World Championship"},
    {day: 'December 12', time: "*EVENING ADULT CLASSES STILL ON"},
    {day: 'December 13', name: "Basics - NoGi", time: "7:30 am - 8:30 am", reason: "Head Coach is away, competing at the Nogi World Championship"},
    {day: 'December 13', name: "Basics - NoGi", time: "12:00 pm - 1:00 pm", reason: "Head Coach is away, competing at the Nogi World Championship"},
    {day: 'December 13', name: "Kids - NoGi", time: "5:30 pm - 6:15 pm", reason: "Head Coach is away, competing at the Nogi World Championship"},
    {day: 'December 13', time: "*EVENING ADULT CLASSES STILL ON"},
    {day: 'December 14', name: "Kids - Gi", time: "11:15 am - 12:00 pm", reason: "Head Coach is away, competing at the Nogi World Championship"},
    {day: 'December 14', time: "*NOON ADULT CLASS IS STILL ON", reason: "Head Coach is away, competing at the Nogi World Championship"},

 
  ];
  export default cancellations  