import comp from '../Media/comp.webp'
import gi from '../Media/gi.webp'
import nogi from '../Media/nogi.jpg'
import kids from '../Media/children.webp'
import mma from '../Media/mma.jpg'

import wrestling from '../Media/wrestling.jpeg'
const Programs = [
    {image: gi,  name:'Gi Jiu-Jitsu', description: 'Training in the gi emphasizes grips, leverage, and technical precision, offering students a deep understanding of BJJ fundamentals. Whether you are a beginner or advanced practitioner, our Gi classes will help you sharpen your skills, improve fitness, and develop a strong sense of discipline. '},
    {image: nogi,name:'No-Gi Jiu-Jitsu', description: 'Our No-Gi Brazilian Jiu-Jitsu program focuses on grappling techniques without the traditional gi, emphasizing speed, agility, and positional control. This program is perfect for those who want to improve their grappling skills in a faster-paced environment.' },
    {image: comp,name:'Competition Training', description: 'Whether you are drilling specific techniques with a partner, sharpening your game, or simply rolling, the focus is on self-directed practice. It is an open, flexible training environment where you can come in, work on what you need, and push yourself to the next level.' },
  
    {image: wrestling,name:'Wrestling', description: 'Wrestling classes provide a dynamic environment to master the art of takedowns and control. Whether you are learning fundamental techniques, improving your transitions, or perfecting your mat skills, these sessions are designed to build strength, endurance, and technical precision.' },

    {image: kids,name:'Kids (ages 8-12)', description: 'Our Kids program is designed to build confidence, discipline, and coordination in a fun and supportive environment. We focus on teaching the fundamentals of BJJ while instilling important life values like respect, perseverance, and teamwork.'},
]

const BackupPrograms = [
    {image: mma,name:'Mixed Martial Arts', description: 'Our Mixed Martial Arts (MMA) program combines the best techniques from various disciplines, including striking, grappling, and ground control. Our MMA program offers comprehensive training for all levels. With a focus on integrating techniques from Brazilian Jiu-Jitsu, Muay Thai, wrestling, and boxing, you will develop the skills needed to be a well-rounded martial artist. This program is ideal for those seeking to challenge themselves both mentally and physically.'}
]

export default Programs;